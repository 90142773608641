import React from "react";

import { Container } from "./styles";

import NotFound from "../../assets/images/undraw_not_found_60pq.svg";

export function Error404() {
  return (
    <Container>
      <h2>Página não encontrada</h2>
      <img
        style={{ width: "40%", minWidth: 250 }}
        src={NotFound}
        alt="Página não encontrada"
      />
    </Container>
  );
}
