import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { Container, Row } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import {
  MainContainer,
  CompanyInfo,
  GeneralCompanyName,
  MyLocationButton,
  CardFooter,
  MainPhrase,
  CardImage,
} from "./style";
import MapIcon from "@material-ui/icons/Map";
import Chip from "@material-ui/core/Chip";

import PersonPinCircleIcon from "@material-ui/icons/PersonPinCircle";
import moment from "moment";
import { haversineDistance } from "../../utils/geoposition";
import currentBusinessHourState from "../../utils/currentBusinessHourState";

const useStyles = makeStyles({
  card: {
    height: 114,
    width: "100%",
    display: "flex",
    marginBottom: 16,
    cursor: "pointer",
    position: "relative",
    borderRadius: 6,
  },
  closed: {
    backgroundColor: "#d50000",
    color: "white",
  },
  open: {
    backgroundColor: "#76ff03",
    color: "black",
  },
});

const weekdaysTranslation = [
  "domingo",
  "segunda",
  "terça",
  "quarta",
  "quinta",
  "sexta",
  "sábado",
];

function formatNextChangeDate(nextChangeDate) {
  if (moment().isSame(nextChangeDate, "day")) {
    return "";
  }

  if (moment().add(1, "day").isSame(nextChangeDate, "day")) {
    return "amanhã";
  }

  if (moment().diff(nextChangeDate, "days") < 7) {
    return weekdaysTranslation[nextChangeDate.format("d")];
  }

  return nextChangeDate.format("[dia] DD/MM");
}

const asOrA = (hour) => {
  return hour >= "00:00" && hour < "02:00" ? "à" : "às";
};

function getBusinessHoursLabel(businessHoursState) {
  if (!businessHoursState) return null;

  if (!businessHoursState.nextChange) {
    if (businessHoursState.open) {
      return "Sempre aberto";
    }

    return "Sempre fechado";
  }

  const nextChangeDate = moment(
    `${businessHoursState.nextChange.date}T${businessHoursState.nextChange.time}:00`
  );

  return [
    businessHoursState.open && "Fecha",
    !businessHoursState.open &&
      (businessHoursState.type === "temporary_state" ? "Reabrirá" : "Abre"),
    formatNextChangeDate(nextChangeDate),
    asOrA(businessHoursState.nextChange.time),
    businessHoursState.nextChange.time.replace(":", "h"),
  ]
    .filter(Boolean)
    .join(" ");
}

const nextImportantHour = (businessHours) => {
  try {
    if (!businessHours) return null;

    const businessHoursState = currentBusinessHourState(businessHours);

    return {
      label: getBusinessHoursLabel(businessHoursState),
      open: businessHoursState.open,
    };
  } catch {
    return null;
  }
};

function setupMenuLink(menuRouteName, menuSubdomain = "app") {
  let rootDomain = window.location.host.split(".").slice(1).join(".");
  if (menuSubdomain) {
    return `https://${menuSubdomain}.${rootDomain}/${menuRouteName}`;
  }

  return `${process.env.REACT_APP_MENU_PATH}/${menuRouteName}`;
}

function Main() {
  const classes = useStyles();
  const user = useSelector((state) => state.user);
  const [companies, setCompanies] = useState([]);
  const [clientPosition, setClientPosition] = useState({
    latitude: null,
    longitude: null,
  });

  const requestGeoposition = useCallback(() => {
    if (window?.navigator?.geolocation) {
      window.navigator.geolocation.getCurrentPosition(
        (position) => {
          setClientPosition({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        () => {
          alert(
            "Infelizmente não temos acesso a sua localização e não podemos dizer qual a unidade mais próxima de você."
          );
        }
      );
    } else {
      alert(
        "Infelizmente não temos acesso a sua localização e não podemos dizer qual a unidade mais próxima de você."
      );
    }
  }, []);

  useEffect(() => {
    if (!user?.companies) {
      return;
    }

    let companiesList = [];

    user.companies.forEach((company) => {
      let distanceToClient = null;

      if (
        company.latitude &&
        company.longitude &&
        clientPosition.latitude &&
        clientPosition.longitude
      ) {
        let destinationPoint = {
          latitude: parseFloat(company.latitude),
          longitude: parseFloat(company.longitude),
        };

        distanceToClient = haversineDistance({
          lat1: clientPosition.latitude,
          lon1: clientPosition.longitude,
          lat2: destinationPoint.latitude,
          lon2: destinationPoint.longitude,
        });
      }

      companiesList.push({
        distanceToClient: distanceToClient,
        ...company,
      });
    });

    let sortedCompanies = [];

    if (clientPosition.latitude && clientPosition.longitude) {
      sortedCompanies = [
        ...companiesList
          .filter((c) => c.distanceToClient !== null)
          .sort((prevValue, currentValue) => {
            if (prevValue.distanceToClient > currentValue.distanceToClient)
              return 1;
            if (prevValue.distanceToClient < currentValue.distanceToClient)
              return -1;
            return 0;
          }),
        ...companiesList.filter((c) => c.distanceToClient === null),
      ];
    } else {
      sortedCompanies = companiesList.sort((prevValue, currentValue) => {
        if (currentValue.isOpen && !prevValue.isOpen) return 1;
        if (!currentValue.isOpen && prevValue.isOpen) return -1;
        if (currentValue.id < prevValue.id) return 1;
        if (currentValue.id > prevValue.id) return -1;
        return 0;
      });
    }

    setCompanies([...sortedCompanies]);
  }, [user, clientPosition]);

  useEffect(() => {
    if (navigator.permissions) {
      navigator.permissions.query({ name: "geolocation" }).then((res) => {
        if (res.state === "granted") {
          requestGeoposition();
        }
      });
    }
  }, [requestGeoposition]);

  return (
    <MainContainer>
      <Container className="p-0">
        <div className="text-center">
          <GeneralCompanyName>{user.group_name}</GeneralCompanyName>
          <MainPhrase>
            {clientPosition.latitude && clientPosition.longitude
              ? "Escolha uma de nossas unidades e faça seu pedido!"
              : "Use sua localização para encontrar a unidade mais próxima de você!"}
          </MainPhrase>
          {!clientPosition.latitude && !clientPosition.longitude && (
            <MyLocationButton onClick={() => requestGeoposition()}>
              <PersonPinCircleIcon />
              <span style={{ marginLeft: 8 }}>Usar minha localização</span>
            </MyLocationButton>
          )}
        </div>

        <hr className="mt-4 mb-4" />

        <Row lg={12}>
          {companies &&
            companies.map((company) => {
              const _nextImportantHour = nextImportantHour(
                company.business_hours
              );

              return (
                <div className="col-12 col-md-6 col-xl-4" key={company.id}>
                  <a
                    target="_self"
                    href={
                      company.custom_domain
                        ? `https://${company.custom_domain}`
                        : setupMenuLink(company.url_name, user.menu_subdomain)
                    }
                  >
                    <Card className={classes.card} variant="outlined">
                      {company.logo && (
                        <CardImage>
                          <img src={company.logo} alt={company.name} />
                        </CardImage>
                      )}

                      <CompanyInfo>
                        <div className="title">{company.name}</div>
                        {company.street &&
                        company.neighborhood &&
                        company.city &&
                        company.state ? (
                          <>
                            <div className="address">{`${company.street}, ${
                              "nº " + company.address_number || "S/N"
                            }`}</div>
                            <div className="address">{`${company.neighborhood}, ${company.city}/${company.state}`}</div>
                          </>
                        ) : (
                          <>
                            <div className="address">Endereço</div>
                            <div className="address">indisponível</div>
                          </>
                        )}

                        <CardFooter>
                          {_nextImportantHour ? (
                            _nextImportantHour.open ? (
                              <div className="open_info">
                                {_nextImportantHour.label}
                              </div>
                            ) : (
                              <div className="closed_info">
                                {_nextImportantHour.label}
                              </div>
                            )
                          ) : (
                            <div className="closed_info">
                              INDISPONÍVEL NO MOMENTO
                            </div>
                          )}

                          {company.distanceToClient && (
                            <Chip
                              style={{
                                color: "#3182ce",
                                border: "1px solid #3182ce",
                                marginRight: -6,
                              }}
                              variant="outlined"
                              color="primary"
                              size="small"
                              icon={<MapIcon />}
                              label={`${parseInt(company.distanceToClient)} km`}
                            />
                          )}
                        </CardFooter>
                      </CompanyInfo>
                    </Card>
                  </a>
                </div>
              );
            })}
        </Row>
      </Container>
    </MainContainer>
  );
}

export default Main;
