import axios from "axios";

export const getCompaniesInfo = async (companiesTokenName) => {
    let endpoint = `${process.env.REACT_APP_API_PATH}/api/menu/users/${companiesTokenName}`;
    try {
      let response = await axios.get(endpoint);
      return response.data;
    } catch (err) {
      return null;
    }
  
}