import styled from "styled-components";

export const MainContainer = styled.div`
  background-color: #F4F5F7;
  padding: 1em;
  height: 100vh;

  a {
    text-decoration: none;
  }
`;

export const GeneralCompanyName = styled.h3`
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  color: #333;
  font-size: 2rem;
  margin-top: 12px;
  margin-bottom: 16px;
`;

export const MainPhrase = styled.p`
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1.1rem;
  max-width: 360px;
  margin: 16px auto;
  color: #444;
  padding-left: 16px;
  padding-right: 16px;
`;

export const CardImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  padding: 8px 0px 8px 8px;
  width: 28%;

  @media screen and (max-width: 600px) {
    padding: 4px 0px 4px 4px;
    width: 23%;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 8px;
  }
`;

export const CompanyInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  width: 72%;
  padding: 0 16px;
  font-family: "Roboto", sans-serif;

  @media screen and (max-width: 600px) {
    padding: 0 12px;
    width: 77%;
  }

  .title {
    color: #444;
    font-size: 0.95rem;
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 8px;
    margin-bottom: 2px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .address {
    font-weight: 400;
    font-size: 0.8em;
    color: #666;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .open_info {
    font-size: 0.8em;
    font-weight: 500;
    color: #38a169;
  }

  .closed_info {
    font-size: 0.8em;
    font-weight: 500;
    color: #e53e3e;
  }
`;

export const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
`;

export const MyLocationButton = styled.div`
  font-family: "Roboto", sans-serif;
  background-color: #890000;
  padding: 10px;
  border-radius: 12px;
  color: white;
  font-weight: bold;
  max-width: 300px;
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
