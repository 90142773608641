import React from "react";
import { Container } from "./styles";

function Loading() {
  return (
    <Container>
      <div className="sk-folding-cube">
        <div className="sk-cube1 sk-cube"></div>
        <div className="sk-cube2 sk-cube"></div>
        <div className="sk-cube4 sk-cube"></div>
        <div className="sk-cube3 sk-cube"></div>
      </div>
    </Container>
  );
}

export default Loading;
