import moment from "moment";
import max from "lodash/max";

export default function currentBusinessHourState(businessHours, now = moment()) {
  function getIntervals(date) {
    const todayDate = date.format("YYYY-MM-DD");
    const todayWeekday = date.locale("en").format("dddd").toLocaleLowerCase();
    const intervals = businessHours.custom_dates?.[todayDate]?.intervals || businessHours[todayWeekday] || [];

    return intervals;
  }

  function getMatchingInterval(date, customIntervals = null) {
    const intervals = customIntervals || getIntervals(date);

    if (!intervals?.length || !date) return null;

    const dateTime = date.format("HH:mm");

    const desiredInterval = intervals.find((interval) => {
      const [intervalStart, intervalEnd] = interval;

      if (dateTime >= intervalStart && dateTime <= intervalEnd) {
        return true;
      }

      return false;
    });

    return desiredInterval;
  }

  function getNextChange(date) {
    const desiredInterval = getMatchingInterval(date);

    if (!desiredInterval) {
      const intervals = getIntervals(date);
      const dateTime = date.format("HH:mm");

      for (const interval of intervals) {
        const [intervalStart] = interval;

        if (intervalStart > dateTime) {
          return {
            date: date.format("YYYY-MM-DD"),
            time: intervalStart,
          };
        }
      }

      const currentDate = date.clone().add(1, "day");

      const maxCustomDate = moment(
        max([currentDate.format("YYYY-MM-DD"), ...Object.keys(businessHours.custom_dates || {})])
      ).add(8, "days");

      while (currentDate.format("YYYY-MM-DD") <= maxCustomDate.format("YYYY-MM-DD")) {
        const [intervalStart] = getIntervals(currentDate)?.[0] || [];

        if (intervalStart) {
          return {
            date: currentDate.format("YYYY-MM-DD"),
            time: intervalStart,
          };
        }

        currentDate.add(1, "day");
      }

      return null;
    }

    if (desiredInterval[1] === "23:59") {
      const currentDate = date.clone();

      let nextChange = {
        date: date.format("YYYY-MM-DD"),
        time: desiredInterval[1],
      };

      const maxCustomDate = moment(
        max([date.format("YYYY-MM-DD"), ...Object.keys(businessHours.custom_dates || {})])
      ).add(7, "days");

      while (currentDate.format("YYYY-MM-DD") <= maxCustomDate.format("YYYY-MM-DD")) {
        currentDate.add(1, "day");

        const [intervalStart, intervalEnd] = getIntervals(currentDate)?.[0] || [];

        if (intervalStart === "00:00") {
          nextChange = {
            date: currentDate.format("YYYY-MM-DD"),
            time: intervalEnd,
          };

          if (intervalEnd !== "23:59") {
            return nextChange;
          }
        } else {
          return nextChange;
        }
      }

      return null;
    }

    return {
      date: date.format("YYYY-MM-DD"),
      time: desiredInterval[1],
    };
  }

  function getNextChangeForTemporaryState() {
    const { temporary_state, temporary_state_end_at } = businessHours;

    const afterTemporaryStateEndAt = moment(temporary_state_end_at).endOf("minute").add(1, "minute");

    const desiredInterval = getMatchingInterval(afterTemporaryStateEndAt);
    const stateAfterTemporaryPeriod = desiredInterval ? "open" : "closed";

    if (stateAfterTemporaryPeriod === temporary_state) {
      return getNextChange(afterTemporaryStateEndAt);
    }

    const temporaryStateEndAt =
      temporary_state === "open" ? moment(temporary_state_end_at) : moment(temporary_state_end_at).add(1, "minute");

    return {
      date: temporaryStateEndAt.format("YYYY-MM-DD"),
      time: temporaryStateEndAt.format("HH:mm"),
    };
  }

  const { temporary_state, temporary_state_end_at } = businessHours;

  if (temporary_state && temporary_state_end_at && moment(temporary_state_end_at).endOf("minute").isAfter(now)) {
    return {
      type: "temporary_state",
      open: temporary_state === "open",
      nextChange: getNextChangeForTemporaryState(now),
    };
  }

  const todayDate = now.format("YYYY-MM-DD");
  const todayCustomDate = businessHours.custom_dates?.[todayDate];

  if (todayCustomDate) {
    return {
      type: "custom_date",
      open: !!getMatchingInterval(now, todayCustomDate.intervals),
      nextChange: getNextChange(now),
    };
  }

  const todayWeekday = now.locale("en").format("dddd").toLocaleLowerCase();
  const todayWeekdayIntervals = businessHours[todayWeekday];

  return {
    type: "weekday",
    open: !!getMatchingInterval(now, todayWeekdayIntervals),
    nextChange: getNextChange(now),
  };
}
