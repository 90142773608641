import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { Error404 } from "./components/Error404";
import Loading from "./components/Loading";
import Main from "./pages/Main";
import { getCompaniesInfo } from "./utils/api";
import { setUser } from "./store/actions/user";

function isDocumentVisible() {
  if (
    typeof document !== "undefined" &&
    typeof document.visibilityState !== "undefined"
  ) {
    return document.visibilityState !== "hidden";
  }
  // always assume it's visible
  return true;
}

function isOnline() {
  if (typeof navigator.onLine !== "undefined") {
    return navigator.onLine;
  }
  // always assume it's online
  return true;
}

const revalidate = (callback) => {
  if (!isDocumentVisible() || !isOnline() || !callback) return;

  callback();
};

export default function App() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [companyGroupName, setCompanyGroupName] = useState(null);
  const route = useRouteMatch("/:company_group_url");

  const updateData = useCallback(() => {
    if (document.visibilityState === "visible") {
      getCompaniesInfo(companyGroupName).then((response) => {
        if (response) {
          dispatch(setUser(response));
        } else {
          setNotFound(true);
        }
      });
    }
  }, [dispatch, companyGroupName]);

  useEffect(() => {
    if (route) {
      setCompanyGroupName(route.params.company_group_url);
    }
  }, [route]);

  useEffect(() => {
    const callback = () => revalidate(updateData);

    window.addEventListener("visibilitychange", callback);
    window.addEventListener("online", callback);

    return () => {
      window.removeEventListener("visibilitychange", callback);
      window.removeEventListener("online", callback);
    };
  }, [updateData]);

  useEffect(() => {
    if (companyGroupName) {
      getCompaniesInfo(companyGroupName)
        .then((response) => {
          if (response) {
            dispatch(setUser(response));
          } else {
            setLoading(false);
            setNotFound(true);
          }
        })
        .finally(() => setLoading(false));
    }
  }, [companyGroupName, dispatch]);

  return notFound ? (
    <Error404 />
  ) : (
    <>
      {loading && <Loading />}
      <Main setLoading={setLoading} />
    </>
  );
}
