import {
  SET_USER
} from "../actions/constants";

const INITIAL_STATE = {
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_USER:
      return { ...action.user };
    default:
      return state;
  }
};
